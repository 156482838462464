import { Key, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAccountRole } from 'interfaces';
import { IPlayer } from 'interfaces/player';
import { authSelector, playerSelector } from 'store';
import { getClansThunk } from 'store/slices/clan/actions';
import { getAllPlayersThunk } from 'store/slices/player/actions';
import { rolesTranslateMap, rolesValuesMap, TPlayerTable } from 'types/player';

import { Loader } from 'components/ui/Loader';
import STable from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';
import { changeClanValue, filteredPlayerTableData } from 'utils';

const getHeadCellsData = (data: IPlayer[]) => [
  {
    id: 'player',
    numeric: false,
    disablePadding: false,
    label: 'Пользователь',
  },
  {
    id: 'external_id',
    numeric: false,
    disablePadding: false,
    label: 'Логин',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
    possibleValues: Array.from(
      new Set(
        data.flatMap((el) =>
          el.roles.map((role) => rolesTranslateMap[role.role_name])
        )
      )
    ),
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    possibleValues: Array.from(
      new Set(data.flatMap((el) => (el.banned ? 'Забанен' : 'Активный')))
    ),
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Подразделение',
    possibleValues: Array.from(
      new Set(data.flatMap((el) => changeClanValue(el.roles) ?? 'Нет'))
    ),
  },
  {
    id: 'computer',
    numeric: false,
    disablePadding: false,
    label: 'Техника',
    possibleValues: Array.from(
      new Set(data.flatMap((el) => el.computer?.name ?? []))
    ),
  },
];

const changeRoleValue = (values: IAccountRole[]) =>
  values.length
    ? values
        .reduce((acc: string[], value: IAccountRole) => {
          if (value.clan_id !== '00000000000000000000000000') {
            if (value.clan_name) {
              acc.push(
                `${value.clan_name} - ${rolesTranslateMap[value.role_name]}`
              );
            } else {
              acc.push(`${rolesTranslateMap[value.role_name]}`);
            }
          }

          return acc;
        }, [])
        .join(', ')
    : 'Не выбрана';

export const PlayersTable = () => {
  const dispatch = useAppDispatch();

  const { filteredList: players, pending } = useAppSelector(playerSelector);

  const { roles, id } = useAppSelector(authSelector);

  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);

  const highPriorityRoles = roles.filter(
    (role) => role.role_name === EUserRole.ADMIN
  );

  const availablePlayers =
    id === rolesValuesMap.root
      ? players
      : players?.filter((player) =>
          player.roles.some((role) =>
            highPriorityRoles.some(
              (highRole) => highRole.clan_id === role.clan_id
            )
          )
        );

  useEffect(() => {
    dispatch(getClansThunk());
    dispatch(getAllPlayersThunk());
  }, []);

  const tableData = useMemo(
    () =>
      selectedFilters.length
        ? filteredPlayerTableData(selectedFilters, availablePlayers)
        : availablePlayers,
    [selectedFilters, availablePlayers]
  );

  const fetchRows = useMemo(
    () => async () => {
      const response: {
        documents: TPlayerTable[];
        total_count: number;
      } = await new Promise((resolve) =>
        resolve({
          total_count: tableData?.length || 0,
          documents: tableData || [],
        })
      );

      return {
        rows: response.documents,
        total: response.total_count,
      };
    },
    [selectedFilters, availablePlayers]
  );

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(tableData),
    [tableData]
  );

  const navigate = useNavigate();

  const handleRowClick = (_row: TPlayerTable, rowId: Key) => {
    navigate(`/admin-panel/player/${rowId}`);
  };

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <STable
      headCells={ENTITIES_HEAD_CELLS}
      rowIdExtractor={(row) => row?.id}
      fetchRows={fetchRows}
      handleRowClick={handleRowClick}
      classNames={{
        root: 'group !h-[calc(100vh-148px)]',
        row: 'group hover:!bg-ultrablack',
        cell: 'group-hover:!bg-[inherit] !border-b-tpg_light !bg-light',
        body: '[&>tr>td]:h-[93px]',
      }}
      onFiltersChange={setSelectedFilters}
      renderRowCells={(row, props) => {
        const roleKey = row.roles.map(({ role_name }) => role_name)?.join(',');

        return (
          <>
            <STableCell
              {...props}
              key={row.name}
              propName="name"
              cellType="text"
            >
              {row.name}
            </STableCell>
            <STableCell
              {...props}
              key={row.name}
              propName="external_id"
              cellType="text"
            >
              {row.external_id}
            </STableCell>
            <STableCell
              {...props}
              key={roleKey}
              propName="role"
              cellType="text"
            >
              {changeRoleValue(row.roles)}
            </STableCell>
            <STableCell
              {...props}
              key={row.status}
              propName="status"
              cellType="text"
            >
              {row.banned ? 'Забанен' : 'Активный'}
            </STableCell>
            <STableCell
              {...props}
              key={row.clans?.join(',')}
              propName="clans"
              cellType="text"
            >
              {changeClanValue(row.roles) || 'Нет'}
            </STableCell>
            <STableCell
              {...props}
              key={`${row.computer?.name} - ${row.name}`}
              propName="computer"
              cellType="text"
            >
              {row.computer?.name || 'Не выбрано'}
            </STableCell>
          </>
        );
      }}
    />
  );
};
