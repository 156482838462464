import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as FullscreenIcon } from 'images/newIcons/fullSize.svg';
import { ReactComponent as MonitorIcon } from 'images/newIcons/monitor.svg';
import { VideoQuality } from 'livekit-client';

import { notify } from 'utils';

interface IControlPanelProps {
  videoEl: HTMLVideoElement | null;
  playerEl: HTMLDivElement | null;
  initialVolume?: number;
  isMain?: boolean;
  isShowSwitchButtonMain?: boolean;
  livekitName?: string;
  onFullscreenChange?: (flag: boolean) => void;
  onQualityChange: (quality: VideoQuality) => void;
  onSelectMainRoom?: (name: string) => void;
}

export const ControlPanel: FC<IControlPanelProps> = ({
  videoEl,
  playerEl,
  isMain,
  isShowSwitchButtonMain,
  livekitName,
  onFullscreenChange,
  onQualityChange,
  onSelectMainRoom,
}) => {
  const [isFullscreen, setFullscreen] = useState(false);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setFullscreen(false);
        onFullscreenChange?.(false);
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const onFullScreen = () => {
    if (isFullscreen) {
      document.exitFullscreen().catch((err) => {
        notify.error(err.message);
      });

      onQualityChange(VideoQuality.MEDIUM);

      setFullscreen(false);
      onFullscreenChange?.(false);
    } else if (playerEl) {
      playerEl.requestFullscreen().catch((err) => {
        if ('message' in err) {
          notify.error(err.message);
        }
      });

      onQualityChange(VideoQuality.HIGH);

      setFullscreen(true);
      onFullscreenChange?.(true);
    }
  };

  return (
    <div
      className={cn(
        'absolute top-0 h-full w-full opacity-0 hover:opacity-100',
        videoEl && 'hover:transition-all'
      )}
    >
      <div
        className={cn(
          'absolute bottom-0 flex h-[48px] w-full items-center justify-end bg-gradient-to-t from-neutral-900 px-[24px]',
          {
            '!justify-between': isShowSwitchButtonMain,
          }
        )}
        style={{ background: 'rgba(10, 12, 23, 0.9)' }}
      >
        {isShowSwitchButtonMain && (
          <div
            className="flex items-center justify-center gap-[4px] cursor-pointer"
            onClick={() => onSelectMainRoom?.(livekitName || '')}
          >
            <span className="tpg-c2">
              {isMain ? 'Отменить' : 'Сделать главным'}
            </span>
            <div className="text-white flex items-center justify-center [&>svg]:w-[14px] [&>svg]:h-[14px]">
              <MonitorIcon />
            </div>
          </div>
        )}
        <div
          className="flex items-center justify-center gap-[4px] cursor-pointer"
          onClick={onFullScreen}
        >
          <span className="tpg-c2">
            {!isFullscreen ? 'На весь экран' : 'Свернуть'}
          </span>
          <div className="text-white flex items-center justify-center [&>svg]:w-[20px] [&>svg]:h-[20px]">
            <FullscreenIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
