import { FC } from 'react';
import cn from 'classnames';

interface EmtyScreenProps {
  text: string;
  className?: string;
}

export const EmtyScreen: FC<EmtyScreenProps> = ({ text, className }) => (
  <div
    className={cn(
      'flex justify-center items-center w-full h-[calc(100vh-105px)] bg-light rounded-[10px]',
      className
    )}
  >
    <span className="tpg-h4 text-tpg_light">{text}</span>
  </div>
);
