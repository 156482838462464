import { EUserRole } from './profile';

export const CLAN_PREFIX = '/clan';

export const appRoutes = {
  STREAMS: '/streams',
  STREAM: '/stream/:id',
  RECORDS: '/records',
  RECORD: '/record/:id',
  CLAN_RECORDS: '/admin-panel/clan/records',
  STREAM_LAUNCH: '/stream-launch',
  STREAM_LAUNCH_APP: '/stream-launch-app',
  CLANS: '/clans',
  CLAN: `${CLAN_PREFIX}/:id`,
  MAP: '/map',
  ADMIN: '/admin-panel',
  ADMIN_PLAYER: '/admin-panel/player/:id',
  ADMIN_NEW_PLAYER: '/admin-panel/player/new',
  ADMIN_CLAN: '/admin-panel/clan/:id',
  ADMIN_CLAN_RECORD: '/admin-panel/clan/:id/record/:id',
  ADMIN_NEW_CLAN: '/admin-panel/clan/new',
  ADMIN_MODERATOR_RECORD: '/admin-panel/record/:id',
};

export const authRoutes = {
  SIGN_IN: '/sign-in',
  LOGGED_IN: '/logged-in',
  FORGOT_PASSWORD: '/forgot-password',
};

export enum STREAMS_COLORS {
  DARK_PRODUCT = '#053C92',
  MAIN_PRODUCT = '#1D65D4',
  BRIGHT_PRODUCT = '#4282e6',
  ULTRABLACK = '#0a0c17',
  DARK = '#171925',
  LIGHT = '#1D1F2D',
  BRIGHT = '#20242E',
  TPG_TITLE = '#e3e6f1',
  TPG_BASE = '#9599a6',
  TPG_LIGHT = '#4D5064',
  SUCCESS = '#42BC1B',
  ERROR = '#EA3737',
}

export type ElementThemeUnion = 'dark' | 'light' | 'ultrablack';

export type ElementDirectionUnion = 'left' | 'right';

export type ElementPlacementUnion = 'top' | 'bottom';

export const NOTHING_WAS_FOUND_MESSAGE = 'Ничего не найдено, увы...';

export const navigation = {
  [EUserRole.MODERATOR]: appRoutes.STREAMS,
  [EUserRole.USER]: appRoutes.STREAMS,
  [EUserRole.ADMIN]: appRoutes.STREAMS,
  [EUserRole.ROOT]: appRoutes.STREAMS,
};
