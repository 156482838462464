import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { HEADER_HEIGHT, PADDING } from 'constants/common';
import { useBreakPoint } from 'hooks';
import { useWindowSize } from 'hooks/useWindowSize';
import { IRoomParams } from 'interfaces';

import { EmtyScreen } from 'components/EmtyScreen';
import { MainRoomPanel } from 'components/MainRoomPanel';
import { Room } from 'components/Room';

interface PanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
  rooms: IRoomParams[];
  clanId?: string;
  onFullscreenChange?: (
    flag: boolean,
    livekitName: string,
    clanId?: string
  ) => void;
}

const GAP = 5;

export const Panel: FC<PanelProps> = ({
  isOpenFullScreen,
  onBack,
  rooms,
  clanId: id,
  onFullscreenChange,
}) => {
  const breakPoint = useBreakPoint();

  const { height } = useWindowSize();

  const [heightPlayer, setHeightPlayer] = useState(0);

  const [mainRoom, setMainRoom] = useState<IRoomParams>();

  const unhiddenRooms = rooms.filter((room) => !room.isHidden);

  useEffect(() => {
    const count =
      (unhiddenRooms.length <= 2 && 1) ||
      (unhiddenRooms.length >= 3 && unhiddenRooms.length <= 6 && 2) ||
      (unhiddenRooms.length >= 7 && unhiddenRooms.length <= 12 && 3) ||
      (unhiddenRooms.length >= 13 && unhiddenRooms.length <= 20 && 4) ||
      5;

    const heightScreen = isOpenFullScreen ? height : height - HEADER_HEIGHT;

    if (height) {
      const value = heightScreen / count;

      if (value) {
        const difference =
          value - (unhiddenRooms.length > 2 ? PADDING - GAP : PADDING);

        setHeightPlayer(difference);
      }
    }
  }, [height, unhiddenRooms, isOpenFullScreen]);

  useEffect(() => {
    const room = unhiddenRooms.find(
      (room) => room.livekitName === mainRoom?.livekitName
    );

    if (!room && mainRoom) {
      setMainRoom(undefined);
    }
  }, [unhiddenRooms, mainRoom]);

  const gridSize =
    ((breakPoint === 'mobile' ||
      breakPoint === 'tablet' ||
      unhiddenRooms.length === 1) &&
      'grid-cols-1') ||
    (unhiddenRooms.length >= 2 && unhiddenRooms.length <= 4 && 'grid-cols-2') ||
    (((unhiddenRooms.length >= 5 && unhiddenRooms.length <= 9) ||
      breakPoint === 'tablet-landscape') &&
      'grid-cols-3') ||
    (unhiddenRooms.length >= 10 &&
      unhiddenRooms.length <= 16 &&
      'grid-cols-4') ||
    'grid-cols-5';

  const handleMainStreamSelect = (livekitName: string) => {
    if (livekitName) {
      const room = unhiddenRooms.find(
        (room) => room.livekitName === livekitName
      );

      setMainRoom((prevRoom) =>
        prevRoom?.livekitName === livekitName ? undefined : room
      );
    } else {
      setMainRoom(undefined);
    }
  };

  return (
    <div className="flex flex-col gap-[16px] w-[calc(100%+48px)] m-[-24px] p-[8px]">
      {isOpenFullScreen && (
        <div
          onClick={onBack}
          className="flex flex-row gap-[5px] absolute left-[50%] translate-x-[-50%] top-0 py-[6px] px-[16px] z-[1] bg-ultrablack/80 rounded-b-[5px]"
        >
          <span className="tpg-c2 text-bright_product">esc</span>
          <span className="tpg-c2">вернуть обратно</span>
        </div>
      )}
      {!unhiddenRooms?.length && <EmtyScreen text="Нет ни одного эфира" />}
      {!!unhiddenRooms?.length &&
        (mainRoom ? (
          <MainRoomPanel
            isOpenFullScreen={isOpenFullScreen}
            mainRoom={mainRoom}
            rooms={unhiddenRooms}
            clanId={id}
            onFullscreenChange={(flag) =>
              onFullscreenChange?.(
                flag,
                mainRoom.livekitName,
                mainRoom.clanId || id
              )
            }
            onSelectMainRoom={handleMainStreamSelect}
          />
        ) : (
          <div className={cn(`grid ${gridSize} gap-[5px] w-full`)}>
            {unhiddenRooms.map(
              ({
                livekitName,
                publisherName,
                token,
                clanId,
                clanName,
                roomName,
                accountId,
              }) => (
                <Room
                  key={livekitName}
                  accountId={accountId}
                  livekitName={livekitName}
                  roomName={roomName}
                  publisherName={publisherName}
                  token={token}
                  clanId={clanId || id}
                  clanName={clanName}
                  onFullscreenChange={(flag) =>
                    onFullscreenChange?.(flag, livekitName, clanId || id)
                  }
                  heightPlayer={heightPlayer}
                  isMain={!!mainRoom}
                  isShowSwitchButtonMain={unhiddenRooms.length > 1}
                  onSelectMainRoom={handleMainStreamSelect}
                />
              )
            )}
          </div>
        ))}
    </div>
  );
};
