import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { ITreeRooms } from 'interfaces/room';

import TextInput from 'components/ui/TextInput';
import { filterTreeRoomsBySearch } from 'utils';

interface RoomListProps {
  rooms: ITreeRooms[];
  onSelectMainRoom: (name: string) => void;
}

export const RoomList: FC<RoomListProps> = ({ rooms, onSelectMainRoom }) => {
  const [searchValue, setSearchValue] = useState('');
  const [treeRooms, setTreeRooms] = useState<ITreeRooms[]>([]);

  const breakPoint = useBreakPoint();

  useEffect(() => {
    if (searchValue) {
      const filteredTreeRooms = filterTreeRoomsBySearch(rooms, searchValue);

      setTreeRooms(filteredTreeRooms);
    } else {
      setTreeRooms(rooms);
    }
  }, [rooms, searchValue]);

  const handleChangeSearch = (value: string) => {
    const filteredTreeRooms = filterTreeRoomsBySearch(rooms, value);

    setTreeRooms(filteredTreeRooms);

    setSearchValue(value);
  };

  const handleRoomSelect = (name: string) => {
    onSelectMainRoom(name);
  };

  return (
    <div
      className={cn(
        'absolute w-[300px] flex flex-col bg-ultrablack border border-solid rounded-[10px] border-tpg_light z-[1] bottom-[78px] right-[19px]',
        {
          '!right-0': breakPoint === 'mobile',
        }
      )}
      onClick={(e) => e.stopPropagation()}
    >
      <TextInput
        onChange={handleChangeSearch}
        value={searchValue}
        placeholder="Начните поиск"
        searchIcon
        inputClassName="!rounded-t-[10px] !rounded-b-none !py-[9px] !pr-[15px] !pl-[28px] !border-none"
        className="[&>div>svg]:!ml-[6px] [&>div>svg]:!w-5 [&>div>svg]:!h-5"
        size="m"
      />
      <div className="max-h-[150px] mb-[5px] overflow-auto">
        <div className="flex flex-col px-[16px] pt-[8px] gap-[4px]">
          {treeRooms.length ? (
            treeRooms.map(({ rooms, clanName, clanId }) => (
              <div key={clanId} className="flex flex-col gap-[4px]">
                <span className="tpg-c2 text-tpg_base">{clanName}</span>
                {rooms.map(({ publisherName, livekitName }) => (
                  <span
                    key={publisherName}
                    onClick={() => handleRoomSelect(livekitName)}
                    className="cursor-pointer ml-[16px] tpg-c2 text-tpg_base"
                  >
                    {publisherName}
                  </span>
                ))}
              </div>
            ))
          ) : (
            <span className="tpg-c2 text-tpg_base">Не найдено</span>
          )}
        </div>
      </div>
    </div>
  );
};
