import { FC, useEffect, useMemo, useState } from 'react';
import { LP_INTERVAL_MS } from 'constants/profile';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IRoom } from 'interfaces';
import { clansSelector } from 'store/slices/clan';
import { roomActions, roomSelector } from 'store/slices/room';
import {
  getAllLiveKitTokensThunk,
  getAllRoomsThunk,
  getClanRoomsThunk,
} from 'store/slices/room/actions';

import { convertRoomNameToParams } from 'utils';

import { Panel } from './Panel';

interface AllClansStreamsPanelProps {
  isOpenFullScreen: boolean;
  onBack: () => void;
}

export const AllClansStreamsPanel: FC<AllClansStreamsPanelProps> = ({
  isOpenFullScreen,
  onBack,
}) => {
  const [openedFullscreenClanRoom, setOpenedFullscreenClanRoom] = useState<{
    livekitName: string;
    clanId: string;
  }>();

  const { treeRooms, rooms: roomsData } = useAppSelector(roomSelector);

  const { clans } = useAppSelector(clansSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(roomActions.setTreeRooms(clans));
  }, [roomsData, clans]);

  useEffect(() => {
    if (openedFullscreenClanRoom?.clanId) {
      const getRooms = () => {
        if (clans) {
          dispatch(getClanRoomsThunk(openedFullscreenClanRoom.clanId));
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    } else {
      const getRooms = () => {
        if (clans) {
          dispatch(getAllRoomsThunk());
        }
      };

      getRooms();

      const roomsInterval = setInterval(getRooms, LP_INTERVAL_MS);

      return () => {
        clearInterval(roomsInterval);
      };
    }
  }, [clans, openedFullscreenClanRoom]);

  const rooms = useMemo(
    () =>
      treeRooms.reduce((acc: IRoom[], data) => {
        if (data.rooms) {
          const convertedRooms = data.rooms.map((room) => ({
            ...room,
            clanName: data.clanName,
            clanId: data.clanId,
          }));

          acc = [...acc, ...convertedRooms];
        }

        return acc;
      }, []),
    [treeRooms]
  );

  useEffect(() => {
    if (rooms.length && !openedFullscreenClanRoom) {
      const newRooms = rooms.filter((newRoom) => !newRoom.token);

      if (newRooms.length) {
        const params = newRooms.map((room) => {
          const { clan_id, room_name } = convertRoomNameToParams(
            room.livekitName
          );

          return {
            clan_id,
            room_name,
          };
        });

        if (params.length) {
          dispatch(getAllLiveKitTokensThunk(params));
        }
      }
    }
  }, [rooms, openedFullscreenClanRoom]);

  const roomList = openedFullscreenClanRoom?.livekitName
    ? rooms.filter(
        (room) => room.livekitName === openedFullscreenClanRoom?.livekitName
      )
    : rooms;

  useEffect(() => {
    if (!roomList.length && openedFullscreenClanRoom) {
      setOpenedFullscreenClanRoom(undefined);

      return;
    }
  }, [roomList]);

  const handleFullscreenChange = (
    isFullOpen: boolean,
    livekitName: string,
    clanId?: string
  ) => {
    if (isFullOpen) {
      if (clanId) {
        setOpenedFullscreenClanRoom({ livekitName, clanId });
      }
    } else {
      setOpenedFullscreenClanRoom(undefined);
    }
  };

  return (
    <Panel
      isOpenFullScreen={isOpenFullScreen}
      onBack={onBack}
      rooms={roomList}
      onFullscreenChange={handleFullscreenChange}
    />
  );
};
