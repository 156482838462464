import { memo, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as Close } from 'images/newIcons/close.svg';

import { NOTHING_WAS_FOUND_MESSAGE } from '../../../constants/routes';
import { TextInput } from '../index';

import BaseFilter from './BaseFilter';

import styles from './Filters.module.scss';

interface EntityParameterFilterProps<V> {
  possibleValues: string[];
  field: string;
  onFilterAdd: (field: string, value: string) => void;
  onRemoveFilter: (field: string, value: string) => void;
  resetFilters: (field: string) => void;
}

const EntityParameterFilter = ({
  possibleValues,
  onFilterAdd,
  onRemoveFilter,
  field,
  resetFilters,
}: EntityParameterFilterProps<string>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleFilterAdd = (value: string) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((el) => el !== value));
      onRemoveFilter(field, value);
    } else {
      setSelectedValues((values) => [...values, value]);
      onFilterAdd(field, value);
    }
  };

  const onClearFilters = () => {
    setSelectedValues([]);
    resetFilters(field);
  };

  const filterPossibleValues = () =>
    possibleValues.filter((value) =>
      value.toLowerCase().includes(searchValue.toLowerCase())
    );

  const renderDropdownContent = () => {
    const filteredValues = filterPossibleValues();

    return (
      <div className="flex flex-col !rounded-[10px]">
        <TextInput
          className={`${styles['search-input']}`}
          value={searchValue}
          placeholder="Начните поиск"
          searchIcon
          maxLength={99999}
          onChange={setSearchValue}
          size="m"
          theme="dark"
          inputClassName="tpg-c2 !rounded-[10px]"
        />
        <div className="bg-ultrablack py-2 gap-1 tpg-c2">
          {filteredValues.length === 0 && (
            <div className="px-4 text-tpg_base tpg-c1">
              {NOTHING_WAS_FOUND_MESSAGE}
            </div>
          )}
          {filteredValues.map((value, idx) => (
            <div
              key={idx}
              className={cn('px-4 hover:text-dark_product', {
                'text-dark_product': selectedValues.includes(value),
              })}
              onClick={() => handleFilterAdd(value)}
            >
              {value}
            </div>
          ))}
        </div>
        <div className="h-8 flex justify-between items-center p-3 tpg-c2 cursor-default">
          <div>{`Выбрано: ${selectedValues.length}`}</div>
          <div
            className="flex flex-row items-center gap-1 cursor-pointer"
            onClick={onClearFilters}
          >
            <div>Сбросить</div>
            <Close className="icon-container" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="absolute">
      <BaseFilter
        isOpen={isOpen}
        isActive={!!selectedValues.length}
        setIsOpen={setIsOpen}
        dropdownContent={renderDropdownContent()}
        containerStyle={{ top: '37px', right: '-10px' }}
      />
    </div>
  );
};

export default memo(EntityParameterFilter);
