import groupBy from 'lodash/groupBy';
import axios from 'services/axios';
import { ID } from 'types';

import { getClanRoomsAdapter, roomAdapter } from './helpers/roomAdapter';
import {
  IAllRoomsResponse,
  IClanRoomsAdapted,
  IClanRoomsResponse,
  ICreateIngressResponse,
  IDeleteRoomInput,
  IGetAllLiveKitTokens,
  IGetLiveKitTokenInput,
  IGetRoomResponse,
  ILiveKitTokenResponse,
  ISignalRoomInput,
  ITokenResponse,
  TCreateRoomInput,
} from './types/room';

export const getClanRooms = async (clanId: ID) => {
  const { data } = await axios.get<IClanRoomsResponse>(
    `/clans/${clanId}/rooms`
  );

  return getClanRoomsAdapter(data);
};

export const getAllRooms = async () => {
  const { data } = await axios.get<IAllRoomsResponse>(`/rooms`);

  const groupedByClan = groupBy(data, (d) => d.clan_id);

  const result: IClanRoomsAdapted[] = Object.entries(groupedByClan).map(
    ([k, v]) => ({
      clanId: k,
      rooms: v.map(roomAdapter),
    })
  );

  return result;
};

export const getRoomParticipants = ({
  clanId,
  roomName,
}: {
  clanId: string;
  roomName: string;
}) => axios.get(`/clans/${clanId}/rooms/${roomName}/participants`);

export const getLiveKitToken = async ({
  clanId,
  roomName,
}: IGetLiveKitTokenInput) => {
  const { data } = await axios.post<ITokenResponse>(
    `/clans/${clanId}/rooms/${roomName}/exchange-token`
  );

  return { ...data, clanId, roomName };
};

export const getAllLiveKitTokens = async (items: IGetAllLiveKitTokens[]) => {
  const { data } = await axios.post<{ items: ILiveKitTokenResponse[] }>(
    '/batch-exchange-token',
    {
      items,
    }
  );

  return data;
};

export const createRoom = async ({ roomName, clanId }: TCreateRoomInput) => {
  const { data } = await axios.post<IGetRoomResponse>(
    `/clans/${clanId}/rooms/${roomName}`
  );

  return roomAdapter(data);
};

export const createRoomIngress = async ({
  roomName,
  clanId,
}: TCreateRoomInput) => {
  const { data } = await axios.post<ICreateIngressResponse>(
    `/clans/${clanId}/rooms/${roomName}/ingress`,
    {
      type: 'RTMP_INPUT',
    }
  );

  return data;
};

export const createRoomChain = async ({
  roomName,
  clanId,
}: TCreateRoomInput) => {
  const {
    livekitName,
    numParticipants,
    accountId,
    isRecording,
    src,
    isActive,
  } = await createRoom({ roomName, clanId });

  const { token } = await getLiveKitToken({ clanId, roomName });

  return {
    livekitName,
    roomName,
    numParticipants,
    accountId,
    token,
    isRecording,
    src,
    isActive,
  };
};

export const getRecordingSignal = async ({
  roomName,
  clanId,
}: ISignalRoomInput) => {
  const { data } = await axios.post(`/clans/${clanId}/rooms/${roomName}/wait`);

  return { ...roomAdapter(data), roomName, clanId };
};

export const deleteRoom = async ({
  roomName,
  clanId,
  params,
}: IDeleteRoomInput) => {
  const { data } = await axios.delete(`/clans/${clanId}/rooms/${roomName}`, {
    data: params,
  });

  return data;
};
