import { FC, ReactNode, useState } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { ReactComponent as CheckIcon } from 'images/newIcons/check.svg';
import { ReactComponent as CloseIcon } from 'images/newIcons/close.svg';

interface AdminTableProps {
  title: string;
  historyTitle: string;
  listTitle: string;
  isShowFooterController: boolean;
  info: ReactNode;
  history: ReactNode;
  list: ReactNode;
  onSave?: () => void;
  onRemove?: () => void;
  isNewData?: boolean;
}

export const AdminTable: FC<AdminTableProps> = ({
  title,
  // historyTitle,
  listTitle,
  isShowFooterController = false,
  info,
  history,
  list,
  onSave,
  onRemove,
  isNewData,
}) => {
  const [isOpenHistory, setOpenHistory] = useState(false);

  const breakPoint = useBreakPoint();

  const handleSelectList = () => {
    setOpenHistory(false);
  };

  // const handleSelectHistory = () => {
  //   setOpenHistory(true);
  // };

  return (
    <div className="border border-solid rounded-[10px] border-tpg_light h-full overflow-hidden">
      <div className="flex flex-row justify-between px-[12px] py-[10px] rounded-t-[10px] bg-dark border-solid border-b border-b-tpg_light">
        <span>{title}</span>
        <div className="flex flex-row gap-[16px]">
          <span
            className={cn(
              'text-tpg_base pointer-events-none',
              // cursor-pointer hover:text-bright_product transition
              {
                // '!text-bright_product': !isOpenHistory,
              }
            )}
            onClick={handleSelectList}
          >
            {listTitle}
          </span>
          {/* TODO: return when history data is ready */}
          {/* {!isNewData && (
            <span
              className={cn(
                'text-tpg_base cursor-pointer hover:text-bright_product transition',
                {
                  '!text-bright_product': isOpenHistory,
                }
              )}
              onClick={handleSelectHistory}
            >
              {historyTitle}
            </span>
          )} */}
        </div>
      </div>
      <div
        className={cn('flex flex-row h-[calc(100%-40px)] overflow-auto', {
          'h-[calc(100%-79px)]': isShowFooterController,
          'flex-col': breakPoint === 'mobile' || breakPoint === 'tablet',
        })}
      >
        {info}
        {isOpenHistory ? history : list}
      </div>
      {isShowFooterController && (
        <div className="flex flex-row justify-end px-[12px] py-[10px] rounded-[10px] bg-dark h-[40px] border-solid border-t border-t-tpg_light">
          <div className="flex flex-row gap-[12px]">
            {!isNewData && onRemove && (
              <div
                className="gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onRemove}
              >
                <CloseIcon className="[&>path]:fill-red" />
                <span className="tpg-c2">Удалить</span>
              </div>
            )}
            {onSave && (
              <div
                className="gap-[4px] flex flex-row cursor-pointer items-center hover:text-bright_product transition"
                onClick={onSave}
              >
                <CheckIcon className="[&>path]:fill-green" />
                <span className="tpg-c2">Сохранить</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
