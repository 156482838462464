import { AxiosError } from 'axios';
import { errorDictionary } from 'constants/errorDictionary';
import { SEPARATOR } from 'constants/livekit';
import { EUserRole } from 'constants/profile';
import {
  IAccountRole,
  IMediaFile,
  IOption,
  IRoom,
  ISelectOption,
  ITag,
  ITreeRooms,
} from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';
import { IRecord } from 'interfaces/record';
import { rolesTranslateMap } from 'types/player';

import { IFilterValue } from 'components/ui/Table/STableHeader';

import { isVideo } from './monitoring';

export * from './coordinates';
export * from './geospoof';
export * from './helpers/date';
export * from './helpers/string';
export * from './monitoring';
export * as notify from './notifications';
export * from './store';
export * from './tokenStore';

export const getTreeSelectOptions = <
  T extends {
    title: string;
    id: number | string;
    sublayers: T[];
    subobjects: unknown[];
    checked?: boolean;
  }
>(
  list: T[],
  editable: T | null
): ISelectOption[] => {
  const options = list.map(
    ({ title, id, sublayers, checked }) =>
      ({
        label: title,
        value: id,
        checked,
        children: sublayers?.length
          ? getTreeSelectOptions(sublayers, editable)
          : null,
      } as ISelectOption)
  );

  if (editable) {
    if (options.some((item: ISelectOption) => item.value === editable.id)) {
      return options.filter((option) => option.value !== editable.id);
    }

    const findParent = (
      acc: ISelectOption | null,
      item: ISelectOption
    ): ISelectOption | null => {
      if (
        item.children?.some(
          (child: ISelectOption) => child.value === editable.id
        )
      ) {
        item.children = item.children.filter(
          (option) => option.value !== editable.id
        );

        return null;
      }

      if (item.children) return item.children.reduce(findParent, acc);

      return acc;
    };

    options.reduce(findParent, null);
  }

  return options;
};

export const getFilteredOptions = (
  list: ISelectOption[],
  value: string
): ISelectOption[] => {
  if (value) {
    return list.filter((item: ISelectOption) => {
      if (item.children) {
        item.children = getFilteredOptions(item.children, value);

        if (item.children.length) {
          return true;
        }
      }

      return item.label.toLowerCase().includes(value.toLowerCase());
    });
  }

  return list;
};

export const getMediaFiles = (
  files: Array<string | Omit<IMediaFile, 'type'> | IMediaFile>
): IMediaFile[] => {
  if (typeof files === 'string') {
    return [
      {
        url: files,
        type: isVideo(files) ? 'video' : 'image',
      },
    ];
  }

  if (files.length) {
    return files.map((item) => ({
      ...(typeof item === 'object' ? item : {}),
      url: typeof item === 'object' ? item.url : item,
      type: isVideo(
        (item as IMediaFile).file
          ? ((item as IMediaFile)?.file as File).name
          : typeof item === 'object'
          ? item.url
          : item
      )
        ? 'video'
        : 'image',
    }));
  }

  return [];
};

export const getMonospacedString = (value: string) => `\`${value}\``;

export const maskPassword = (password: string) => password.replace(/./g, '*');

export const getWordDayForm = (
  quantity = 0,
  words: [string, string, string]
) => {
  const string = quantity.toString();
  const lastChar = string.charAt(string.length - 1);

  switch (true) {
    case lastChar === '1' && !(quantity === 11):
      return words[0];
    case lastChar === '2' && !(quantity === 12):
    case lastChar === '3' && !(quantity === 13):
    case lastChar === '4' && !(quantity === 14):
      return words[1];

    default:
      return words[2];
  }
};

export const generatePassword = (length: number) => {
  const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
  const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const digits = '0123456789';

  const str = lowerCaseLetters + upperCaseLetters + digits;

  let password = '';

  for (let i = 1; i <= length; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    password += str.charAt(char);
  }

  return password;
};

export const filterOptionsByUniqueClan = (
  options: IOption[],
  players: IClanPlayer[]
) =>
  options.filter(
    (option) => !players.some((player) => player.clanId === option.value)
  );

export const mergeUniqueRecordTags = (records: IRecord[]) => {
  const uniqueTags = new Set<string>();

  records.forEach((record) => {
    if (record.tags) {
      record.tags.forEach((tag) => uniqueTags.add(tag));
    }
  });

  return Array.from(uniqueTags);
};

export const filterRecordBySelectedTags = (
  records: IRecord[],
  tags: ITag[]
) => {
  const str = tags.map((tag) => tag.title);

  return records.filter((record) => {
    if (Array.isArray(record.tags)) {
      return record.tags.some((item) => str.includes(item));
    }

    return false;
  });
};

export const getOptionsByFilteredTags = (options: IOption[], tags: string[]) =>
  tags.flatMap((tag) =>
    options.some((item) => tag.includes(item.label))
      ? [{ value: tag, label: tag }]
      : []
  );

export const filterTreeRoomsBySearch = (
  treeRooms: ITreeRooms[],
  searchValue: string
) =>
  treeRooms.filter(({ clanName, rooms }) => {
    if (clanName.includes(searchValue)) {
      return true;
    }

    if (Array.isArray(rooms)) {
      return rooms.some((room) => room.livekitName.includes(searchValue));
    }

    return false;
  });

export const getErrorMessage = (
  error: AxiosError<{ code: string; message: string }>,
  defaultMessage: string
) => {
  if (error.response?.data) {
    const { code, message } = error.response.data;

    const key = Object.keys(errorDictionary[code]).find((key) =>
      message.includes(key)
    );

    return key ? errorDictionary[code][key] : defaultMessage;
  }

  return defaultMessage;
};

export const convertRoomNameToParams = (room: string) => {
  const params = room.split(SEPARATOR);

  return {
    clan_id: params[0],
    room_name: params.slice(1).join(SEPARATOR),
  };
};
export const changeClanValue = (values: IAccountRole[]) =>
  values.length
    ? values.filter((value) => value.role_name === EUserRole.ADMIN)[0]
        ?.clan_name
    : 'Нет';

export const filteredDataBySearchValue = (
  data: Record<string, string>[],
  searchValue: string
) =>
  data?.filter((value) =>
    Object.values(value).some((value) =>
      String(value)?.toLowerCase().includes(searchValue.toLowerCase())
    )
  );

export const updateTreeRoom = (rooms: IRoom[], activeRooms: IRoom[]) =>
  activeRooms.reduce((acc: IRoom[], activeRoom) => {
    const isExistingRoom = rooms.some(
      (el) => el.livekitName === activeRoom.livekitName
    );

    if (isExistingRoom) {
      const foundRoom = rooms.find(
        (el) => el.livekitName === activeRoom.livekitName
      );

      acc = [...acc, { ...activeRoom, isHidden: foundRoom?.isHidden }];
    } else {
      const newRoom = rooms.find(
        (el) => el.livekitName !== activeRoom.livekitName
      );

      if (newRoom) {
        acc = [...acc, { ...activeRoom, isHidden: false }];
      }
    }

    return acc;
  }, []);

export const filteredPlayerTableData = (
  filter: IFilterValue[],
  data: IPlayer[]
) => {
  const filteredPlayer = data.filter((item) =>
    filter.every(({ id, values }) => {
      if (id === 'role') {
        return item.roles.some((role) =>
          values.includes(rolesTranslateMap[role.role_name])
        );
      }

      if (id === 'status') {
        return values.includes(item.banned ? 'Забанен' : 'Активный');
      }

      if (id === 'clan') {
        const role = changeClanValue(item.roles);

        return values.includes(role ? role : 'Нет');
      }

      if (id === 'computer') {
        return values.includes(item.computer?.name ?? '');
      }
    })
  );

  return filteredPlayer;
};
