import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { useBreakPoint } from 'hooks';
import { IOption } from 'interfaces';

import { CollapseContainer } from 'components/CollapseContainer';
import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Select, TextInput } from 'components/ui';
import { maskPassword } from 'utils';

import { TPlayerInfo } from '.';

interface BasicInfoProps {
  playerInfo: TPlayerInfo;
  computerOptions: IOption[];
  onChange: (info: TPlayerInfo) => void;
  isAccessEditing: boolean;
  isNewPlayer?: boolean;
}

const mockTableHead = [
  {
    id: 'name',
    label: 'Позывной',
  },
  // {
  //   id: 'launchedStreams',
  //   label: 'Записанных эфиров',
  // },
  {
    id: 'login',
    label: 'Логин',
  },
  {
    id: 'password',
    label: 'Пароль',
  },
];

export const BasicInfo: FC<BasicInfoProps> = ({
  playerInfo,
  computerOptions,
  onChange,
  isAccessEditing,
  isNewPlayer,
}) => {
  const [selectedComputer, setSelectedComputer] = useState<IOption>({
    value: '',
    label: '',
  });

  const [isFocus, setFocus] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const tableHeader = isNewPlayer
    ? mockTableHead
    : [
        ...mockTableHead,
        {
          id: 'computer',
          label: 'Техника',
        },
      ];

  const breakPoint = useBreakPoint();

  useEffect(() => {
    const computerInitialValue = computerOptions.find(
      (option) => option.value === playerInfo.computer?.id
    );

    if (computerInitialValue) {
      setSelectedComputer(computerInitialValue);
    }
  }, [playerInfo, computerOptions]);

  const handleChangeName = (value: string) => {
    onChange({ ...playerInfo, name: value });
  };

  const handleSelectComputer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedComputer(value);

      onChange({
        ...playerInfo,
        computer: { id: value.value, name: value.label },
      });
    }
  };

  const handleChangeLogin = (value: string) => {
    onChange({ ...playerInfo, email: value });
  };

  const handleChangePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const handleGeneratePassword = (value: string) => {
    onChange({ ...playerInfo, password: value });
  };

  const handleFocus = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(true);
  };

  const handleBlur = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
  };

  const password = useMemo(
    () =>
      !isNewPlayer && !playerInfo.password && !isFocus
        ? maskPassword('password')
        : playerInfo.password,
    [playerInfo.password, isFocus]
  );

  return !(breakPoint === 'mobile' || breakPoint === 'tablet') ? (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-row border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-col w-6/12 border-r border-solid border-r-dark_product min-w-[195px]">
            {tableHeader.map((head) => (
              <div
                key={head.id}
                className="p-[12px] border-b border-solid border-b-dark_product h-[44px] last:!border-none"
              >
                <span className="tpg-c1">{head.label}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-6/12">
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <TextInput
                  onChange={handleChangeName}
                  placeholder="Введите название"
                  value={playerInfo.name}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              ) : (
                <span>{playerInfo.name}</span>
              )}
            </div>
            {/* TODO: return when launched streams data is ready */}
            {/* <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              <span>{playerInfo.launchedStreams ?? 0}</span>
            </div> */}
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
                <div className="flex flex-row items-center gap-[8px]">
                  <TextInput
                    value={playerInfo.email}
                    inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                    className="w-full"
                    placeholder="Введите значение"
                    onChange={handleChangeLogin}
                  />
                  <CopyIcon value={playerInfo.email} />
                </div>
              ) : (
                <span>{playerInfo.email}</span>
              )}
            </div>
            <div
              className={cn('p-[12px] h-[44px] text-tpg_base tpg-c1', {
                'border-b border-solid border-b-dark_product': !isNewPlayer,
              })}
            >
              <div className="flex flex-row justify-between items-center">
                {!isNewPlayer && (!isAccessEditing || playerInfo.banned) && (
                  <span className="tpg-c1 pointer-events-none">
                    {maskPassword('password')}
                  </span>
                )}
                {(isAccessEditing || isNewPlayer) && !playerInfo.banned && (
                  <>
                    <TextInput
                      onChange={handleChangePassword}
                      value={password || ''}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      inputClassName="bg-ultrablack h-[20px] !pl-0 !text-tpg_base"
                    />
                    <div className="flex flex-row gap-[8px]">
                      <GeneratePassword
                        onClick={handleGeneratePassword}
                        label="Сгенерировать"
                      />
                      {playerInfo.password && (
                        <CopyIcon value={playerInfo.password} />
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {!isNewPlayer && (
              <div className="p-[12px] h-[44px] text-tpg_base tpg-c1">
                {isAccessEditing && !playerInfo.banned ? (
                  <Select
                    value={selectedComputer}
                    options={computerOptions}
                    onSelect={handleSelectComputer}
                    className="!min-h-[20px] !pr-0"
                  />
                ) : (
                  <span>{selectedComputer.label || 'Не выбрано'}</span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col px-[16px] gap-[16px] pt-[16px]">
      <span>Основная информация</span>
      <div ref={containerRef}>
        <CollapseContainer
          hidingBlock={
            <>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Пароль</span>
                <div className="flex flex-row justify-between items-center">
                  {!isNewPlayer && (!isAccessEditing || playerInfo.banned) && (
                    <span className="tpg-c1 pointer-events-none">
                      {maskPassword('password')}
                    </span>
                  )}
                  {(isAccessEditing || isNewPlayer) && !playerInfo.banned && (
                    <>
                      <TextInput
                        onChange={handleChangePassword}
                        value={password || ''}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputClassName="bg-ultrablack h-[20px] !pl-0"
                      />
                      <div className="flex flex-row gap-[8px]">
                        <GeneratePassword
                          onClick={handleGeneratePassword}
                          label="Сгенерировать"
                        />
                        {playerInfo.password && (
                          <CopyIcon value={playerInfo.password} />
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!isNewPlayer && (
                <div className="relative tpg-c1 z-[3]">
                  {isAccessEditing && !playerInfo.banned ? (
                    <Select
                      value={selectedComputer}
                      options={computerOptions}
                      onSelect={handleSelectComputer}
                      placeholder="Техника"
                      className="pl-[16px] border-t border-solid border-t-dark_product"
                    />
                  ) : (
                    <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                      <span className="tpg-c2 text-tpg_light">Техника</span>
                      <span className="tpg-c1 pointer-events-none">
                        {selectedComputer.label || 'Не выбрано'}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </>
          }
        >
          <div className="h-[61px] flex flex-col px-[16px] py-[10px]">
            <span className="tpg-c2 text-tpg_light">Позывной</span>
            {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
              <TextInput
                onChange={handleChangeName}
                placeholder="Введите название"
                value={playerInfo.name}
                inputClassName="bg-ultrablack !h-[19px] !pl-0"
              />
            ) : (
              <span>{playerInfo.name}</span>
            )}
          </div>
          <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Логин</span>
            {(isAccessEditing || isNewPlayer) && !playerInfo.banned ? (
              <div className="flex flex-row items-center gap-[8px]">
                <TextInput
                  value={playerInfo.email}
                  inputClassName="bg-ultrablack h-[19px] !pl-0"
                  className="w-full"
                  placeholder="Введите значение"
                  onChange={handleChangeLogin}
                />
                <CopyIcon value={playerInfo.email} />
              </div>
            ) : (
              <span>{playerInfo.email}</span>
            )}
          </div>
        </CollapseContainer>
      </div>
    </div>
  );
};
