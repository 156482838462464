import { ElementType, FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import cn from 'classnames';
import { ELStorageKeys } from 'constants/localStorage';
import { EUserRole, roleWeights } from 'constants/profile';
import {
  appRoutes,
  authRoutes,
  CLAN_PREFIX,
  navigation,
} from 'constants/routes';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { MainLayout } from 'layouts/MainLayout';
import { AdminScreen } from 'routes/Admin';
import { RightHeaderContent } from 'routes/Admin/components/RightHeaderContent';
import { SearchInputByTable } from 'routes/Admin/components/SearchInputByTable';
import { ClanAdminPanelScreen } from 'routes/ClanAdminPanel';
import { ClansScreen } from 'routes/Clans';
import { HeaderNavigation } from 'routes/Clans/HeaderNavigation';
import { LoggedInScreen } from 'routes/LoggedIn';
import { Login } from 'routes/Login';
import { MapScreen } from 'routes/Map';
import { PlayerAdminPanelScreen } from 'routes/PlayerAdminPanel';
import { RecordScreen } from 'routes/Record';
import { RecordsScreen } from 'routes/RecordsScreen';
import { StreamScreen } from 'routes/Stream';
import { StreamLaunchScreen } from 'routes/StreamLaunch';
import { StreamLaunchAppScreen } from 'routes/StreamLaunchApp';
import { StreamsPanelScreen } from 'routes/StreamsPanel';
import { UserStreamsLayout } from 'routes/UserStreamsLayout';
import { authActions, authSelector } from 'store';
import { getAccountThunk } from 'store/slices/account/actions';

import { BackButton } from 'components/BackButton';
import { ControlPanelMenu } from 'components/ControlPanelMenu';
import { FilterStreams } from 'components/FilterStreams';
import { FullScreenButton } from 'components/FullScreenButton';
import { NotFound } from 'components/NotFound';
import { FilterButton } from 'components/ui/FilterButton';
import { Loader } from 'components/ui/Loader';
import { getToken } from 'utils';
import { getLStorage } from 'utils/helpers/localStorage';

type TRouteProps = {
  path: string;
  component: ReactNode;
  layout: ElementType | null;
  rightHeaderButton?: ReactNode;
  leftContent?: ReactNode;
  centralContent?: ReactNode;
  isPageControlPanel?: boolean;
  isShowHeader?: boolean;
  isStartStream?: boolean;
  resetOptions?: () => void;
};

const authPages = Object.values(authRoutes);

const publicPages = Object.values({
  ...appRoutes,
});

const getProperRedirect = (path: string) => {
  const { role } = useAppSelector(authSelector);

  const accessToken = getToken();

  if (!accessToken && publicPages.includes(path)) {
    return <Navigate to={authRoutes.SIGN_IN} replace />;
  }

  if (accessToken && authPages.includes(path)) {
    return <Navigate to={role ? navigation[role] : '/'} replace />;
  }

  return null;
};

const AppRoute: FC<TRouteProps> = ({
  component,
  layout,
  path,
  rightHeaderButton,
  leftContent,
  centralContent,
  isPageControlPanel,
  isShowHeader,
  isStartStream,
  resetOptions,
}) => {
  const redirect = getProperRedirect(path);

  if (redirect) return redirect;

  if (layout) {
    const Layout = layout;

    return (
      <Layout
        rightHeaderButton={rightHeaderButton && rightHeaderButton}
        centralContent={centralContent && centralContent}
        leftContent={leftContent && leftContent}
        isPageControlPanel={isPageControlPanel}
        isShowHeader={isShowHeader}
        isStartStream={isStartStream}
        resetOptions={resetOptions}
      >
        {component}
      </Layout>
    );
  }

  return <>{component}</>;
};

export const App: FC = (): JSX.Element => {
  const [isOpenFullScreen, setOpenFullScreen] = useState(false);
  const [isOpenFilter, setOpenFilter] = useState(false);

  const { role } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const accountId = getLStorage(ELStorageKeys.accountId);

    if (!accountId) {
      dispatch(authActions.logout());
    } else {
      dispatch(getAccountThunk(accountId));
    }
  }, []);

  const handleOpenFullScreen = (value: boolean) => {
    setOpenFullScreen(value);
  };

  const handleClickFilter = (value: boolean) => {
    setOpenFilter(value);
  };

  const handleResetOptions = () => {
    setOpenFilter(false);
    setOpenFullScreen(false);
  };

  const breakPoint = useBreakPoint();

  if (!role) {
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path={authRoutes.SIGN_IN}
        element={
          <AppRoute
            path={authRoutes.SIGN_IN}
            component={<Login />}
            layout={null}
          />
        }
      />
      <Route path={authRoutes.LOGGED_IN} element={<LoggedInScreen />} />
      {roleWeights[role] >= roleWeights.admin && (
        <>
          <Route
            path={appRoutes.ADMIN_NEW_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_PLAYER}
                component={<PlayerAdminPanelScreen isNewPlayer />}
                layout={MainLayout}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_CLAN}
            element={
              <AppRoute
                path={appRoutes.ADMIN_CLAN}
                component={<ClanAdminPanelScreen />}
                layout={MainLayout}
                rightHeaderButton={role === EUserRole.ROOT || <div />}
                isStartStream={false}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_PLAYER}
            element={
              <AppRoute
                path={appRoutes.ADMIN_PLAYER}
                component={<PlayerAdminPanelScreen />}
                layout={MainLayout}
                rightHeaderButton={role === EUserRole.ROOT || <div />}
                isStartStream={false}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN_NEW_CLAN}
            element={
              <AppRoute
                path={appRoutes.ADMIN_NEW_CLAN}
                component={<ClanAdminPanelScreen isNewClan />}
                layout={MainLayout}
                leftContent={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.ADMIN}
            element={
              <AppRoute
                path={appRoutes.ADMIN}
                component={<AdminScreen />}
                layout={MainLayout}
                rightHeaderButton={<RightHeaderContent />}
                leftContent={<SearchInputByTable />}
              />
            }
          />
        </>
      )}
      {roleWeights[role] >= roleWeights.moderator && (
        <>
          <Route
            path={appRoutes.CLAN_RECORDS}
            element={
              <AppRoute
                path={appRoutes.CLAN_RECORDS}
                component={<RecordsScreen />}
                rightHeaderButton={<BackButton />}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={appRoutes.MAP}
            element={
              <AppRoute
                path={appRoutes.MAP}
                component={<MapScreen />}
                layout={MainLayout}
                isPageControlPanel={false}
              />
            }
          />
        </>
      )}
      {role === EUserRole.USER && (
        <Route
          path={CLAN_PREFIX}
          element={
            <AppRoute
              path={CLAN_PREFIX}
              component={<UserStreamsLayout />}
              layout={MainLayout}
              centralContent={<HeaderNavigation />}
            />
          }
        />
      )}
      {(role === EUserRole.USER || role === EUserRole.ROOT) && (
        <>
          <Route
            path={appRoutes.STREAM_LAUNCH}
            element={
              <AppRoute
                path={appRoutes.STREAM_LAUNCH}
                component={<StreamLaunchScreen />}
                layout={MainLayout}
                rightHeaderButton={<BackButton />}
              />
            }
          />
          <Route
            path={appRoutes.STREAM_LAUNCH_APP}
            element={
              <AppRoute
                path={appRoutes.STREAM_LAUNCH_APP}
                component={<StreamLaunchAppScreen />}
                layout={MainLayout}
                rightHeaderButton={<BackButton />}
              />
            }
          />
        </>
      )}
      <Route
        path={appRoutes.CLAN}
        element={
          <AppRoute
            path={appRoutes.CLAN}
            component={<RecordsScreen />}
            layout={MainLayout}
            leftContent={<BackButton path="/records" />}
            rightHeaderButton={
              role === EUserRole.USER || role === EUserRole.ROOT || <div />
            }
            centralContent={
              !(breakPoint === 'mobile' || breakPoint === 'tablet') && (
                <HeaderNavigation />
              )
            }
            isShowHeader={!isOpenFullScreen}
            isStartStream={role === EUserRole.USER || role === EUserRole.ROOT}
            resetOptions={handleResetOptions}
          />
        }
      />
      <Route
        path={appRoutes.RECORDS}
        element={
          <AppRoute
            path={appRoutes.RECORDS}
            component={<ClansScreen />}
            layout={MainLayout}
            rightHeaderButton={true}
            isStartStream={role === EUserRole.USER || role === EUserRole.ROOT}
          />
        }
      />
      <Route
        path={appRoutes.STREAMS}
        element={
          <AppRoute
            path={appRoutes.STREAMS}
            component={
              <StreamsPanelScreen
                onCloseFullScreen={handleOpenFullScreen}
                isOpenFullScreen={isOpenFullScreen}
              />
            }
            layout={MainLayout}
            rightHeaderButton={
              (role === EUserRole.USER || role === EUserRole.ROOT) &&
              (breakPoint === 'mobile' || breakPoint === 'tablet') ? (
                <ControlPanelMenu
                  content={
                    <>
                      <FilterButton
                        label="Фильтр по трансляциям"
                        onClick={handleClickFilter}
                        isOpen={isOpenFilter}
                        content={<FilterStreams />}
                        rightPosition="-13px"
                        topPosition="66px"
                        classNameContent={
                          breakPoint === 'mobile' || breakPoint === 'tablet'
                            ? '!right-[-68px] !min-w-[310px]'
                            : ''
                        }
                      />
                      <FullScreenButton
                        isActive={isOpenFullScreen}
                        onClick={handleOpenFullScreen}
                      />
                    </>
                  }
                  className={
                    breakPoint === 'mobile' || breakPoint === 'tablet'
                      ? '!right-[-100px]'
                      : ''
                  }
                />
              ) : (
                <div
                  className={cn('flex flex-row gap-[16px]', {
                    '!gap-[5px]': breakPoint === 'mobile',
                  })}
                >
                  <FilterButton
                    label="Фильтр по трансляциям"
                    onClick={handleClickFilter}
                    isOpen={isOpenFilter}
                    content={<FilterStreams />}
                    topPosition="35px"
                    rightPosition="185px"
                    classNameContent={
                      breakPoint === 'mobile' || breakPoint === 'tablet'
                        ? '!right-[-122px] !min-w-[310px]'
                        : ''
                    }
                    className={
                      breakPoint === 'mobile' || breakPoint === 'tablet'
                        ? '!gap-[2px]'
                        : ''
                    }
                  />
                  <FullScreenButton
                    isActive={isOpenFullScreen}
                    onClick={handleOpenFullScreen}
                  />
                </div>
              )
            }
            isShowHeader={!isOpenFullScreen}
            isStartStream={role === EUserRole.USER || role === EUserRole.ROOT}
            resetOptions={handleResetOptions}
          />
        }
      />
      <Route
        path={appRoutes.RECORD}
        element={
          <AppRoute
            path={appRoutes.RECORD}
            component={<RecordScreen />}
            layout={MainLayout}
            leftContent={<BackButton />}
            rightHeaderButton={<div />}
          />
        }
      />
      <Route
        path={appRoutes.STREAM}
        element={
          <AppRoute
            path={appRoutes.STREAM}
            component={<StreamScreen />}
            layout={MainLayout}
            rightHeaderButton={<BackButton />}
          />
        }
      />
      <Route path="/" element={<Navigate replace to={navigation[role]} />} />
      <Route
        path="*"
        element={
          role ? <Navigate replace to={navigation[role]} /> : <NotFound />
        }
      />
    </Routes>
  );
};
