import { FC, useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { useEscapeDown } from 'hooks/useEscapeDown';
import { getClansThunk } from 'store/slices/clan/actions';

import { AllClansStreamsPanel } from './AllClansStreamsPanel';

interface StreamsPanelScreenProps {
  isOpenFullScreen: boolean;
  onCloseFullScreen: (flag: boolean) => void;
}

export const StreamsPanelScreen: FC<StreamsPanelScreenProps> = ({
  isOpenFullScreen,
  onCloseFullScreen,
}) => {
  const dispatch = useAppDispatch();

  useEscapeDown(() => onCloseFullScreen(false));

  useEffect(() => {
    dispatch(getClansThunk());

    return () => {
      onCloseFullScreen(false);
    };
  }, []);

  const handleClickBack = () => {
    onCloseFullScreen(false);
  };

  return (
    <AllClansStreamsPanel
      isOpenFullScreen={isOpenFullScreen}
      onBack={handleClickBack}
    />
  );
};
