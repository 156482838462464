import { FC, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { useBreakPoint } from 'hooks';
import { IClan } from 'interfaces/clan';

import { CollapseContainer } from 'components/CollapseContainer';
import { TextInput } from 'components/ui';

interface BasicInfoProps {
  role: EUserRole;
  clanInfo: IClan;
  onChange: (value: Partial<IClan>) => void;
  isNewClan?: boolean;
}

const mockTableHead = [
  {
    id: 'clan',
    label: 'Подразделение',
  },
  {
    id: 'computer',
    label: 'Техника',
  },
  {
    id: 'workArea',
    label: 'Район работы',
  },
  {
    id: 'longitude',
    label: 'Долгота',
  },
  {
    id: 'latitude',
    label: 'Широта',
  },
  {
    id: 'coordinates',
    label: 'Координаты полигона',
  },
];

export const BasicInfo: FC<BasicInfoProps> = ({
  role,
  clanInfo,
  onChange,
  isNewClan,
}) => {
  const [computerRowHeight, setComputerRowHeight] = useState(0);

  const computerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const breakPoint = useBreakPoint();

  useEffect(() => {
    if (computerRef.current) {
      setComputerRowHeight(computerRef.current.clientHeight + 1);
    }
  }, [computerRef.current]);

  const tableHead = useMemo(() => {
    if (!isNewClan) {
      return mockTableHead;
    } else {
      return mockTableHead.filter((value) => value.id !== 'computer');
    }
  }, [isNewClan]);

  const handleChangeClan = (value: string) => {
    onChange({ name: value });
  };

  const handleChangeWorkArea = (value: string) => {
    onChange({ work_area: value });
  };

  const handleChangeLongitude = (value: string) => {
    onChange({ longitude: Number(value) });
  };

  const handleChangeLatitude = (value: string) => {
    onChange({ latitude: Number(value) });
  };

  const handleChangeCoordinates = (value: string) => {
    const coordinates: number[][][] = [
      value
        .split(',')
        .map(Number)
        .reduce((acc: number[][], value, index) => {
          if (index % 2 === 0) {
            acc.push([value]);
          } else {
            acc[acc.length - 1].push(value);
          }

          return acc;
        }, []),
    ];

    onChange({ coordinates });
  };

  const computerValue = useMemo(
    () => clanInfo.computers.map((computer) => computer.name).join(', '),
    [clanInfo]
  );

  return !(breakPoint === 'mobile' || breakPoint === 'tablet') ? (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-row border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-col w-6/12 border-r border-solid border-r-dark_product min-w-[195px]">
            {tableHead.map(({ id, label }) => (
              <div
                key={id}
                style={{
                  minHeight:
                    id === 'computer' ? `${computerRowHeight}px` : '44px',
                }}
                className="p-[12px] border-b border-solid border-b-dark_product max-h-[44px] last:!border-none"
              >
                <span className="tpg-c1">{label}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-6/12">
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.name}</span>
              ) : (
                <TextInput
                  onChange={handleChangeClan}
                  placeholder="Введите название"
                  value={clanInfo.name}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            {!isNewClan && (
              <div
                ref={computerRef}
                className="p-[12px] border-b border-solid border-b-dark_product min-h-[44px] text-tpg_base tpg-c1"
              >
                <span>{computerValue || 'Не выбрано'}</span>
              </div>
            )}
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.work_area || 'Не выбрано'}</span>
              ) : (
                <TextInput
                  onChange={handleChangeWorkArea}
                  placeholder="Введите значение"
                  value={clanInfo.work_area}
                  inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.longitude}</span>
              ) : (
                <TextInput
                  onChange={handleChangeLongitude}
                  placeholder="Введите значение"
                  value={clanInfo.longitude}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.latitude}</span>
              ) : (
                <TextInput
                  onChange={handleChangeLatitude}
                  placeholder="Введите значение"
                  value={clanInfo.latitude}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            <div
              className={cn('p-[12px] h-[44px] text-tpg_base tpg-c1', {
                'overflow-auto': role === EUserRole.MODERATOR,
              })}
            >
              {role === EUserRole.MODERATOR ? (
                <span className="pr-[12px]">{clanInfo.coordinates}</span>
              ) : (
                <TextInput
                  onChange={handleChangeCoordinates}
                  placeholder="Введите значение"
                  value={
                    clanInfo.coordinates.length
                      ? clanInfo.coordinates.flat().join(',')
                      : ''
                  }
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col px-[16px] gap-[16px] pt-[16px]">
      <span>Основная информация</span>
      <div ref={containerRef}>
        <CollapseContainer
          hidingBlock={
            <>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Долгота</span>
                {role === EUserRole.MODERATOR ? (
                  <span>{clanInfo.longitude}</span>
                ) : (
                  <TextInput
                    onChange={handleChangeLongitude}
                    placeholder="Введите значение"
                    value={clanInfo.longitude}
                    inputClassName="bg-ultrablack !h-[19px] !pl-0"
                  />
                )}
              </div>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Широта</span>
                {role === EUserRole.MODERATOR ? (
                  <span>{clanInfo.latitude}</span>
                ) : (
                  <TextInput
                    onChange={handleChangeLatitude}
                    placeholder="Введите значение"
                    value={clanInfo.latitude}
                    inputClassName="bg-ultrablack !h-[19px] !pl-0"
                  />
                )}
              </div>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">
                  Координаты полигона
                </span>
                <div
                  className={cn('tpg-c1', {
                    'overflow-auto': role === EUserRole.MODERATOR,
                  })}
                >
                  {role === EUserRole.MODERATOR ? (
                    <span className="pr-[12px]">{clanInfo.coordinates}</span>
                  ) : (
                    <TextInput
                      onChange={handleChangeCoordinates}
                      placeholder="Введите значение"
                      value={
                        clanInfo.coordinates.length
                          ? clanInfo.coordinates.flat().join(',')
                          : ''
                      }
                      inputClassName="bg-ultrablack !h-[19px] !pl-0"
                    />
                  )}
                </div>
              </div>
            </>
          }
        >
          <div className="h-[61px] flex flex-col px-[16px] py-[10px]">
            <span className="tpg-c2 text-tpg_light">Подразделение</span>
            {role === EUserRole.MODERATOR ? (
              <span>{clanInfo.name}</span>
            ) : (
              <TextInput
                onChange={handleChangeClan}
                placeholder="Введите название"
                value={clanInfo.name}
                inputClassName="bg-ultrablack !h-[19px] !pl-0"
              />
            )}
          </div>
          {!isNewClan && (
            <div className="min-h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
              <span className="tpg-c2 text-tpg_light">Техника</span>
              <span>{computerValue || 'Не выбрано'}</span>
            </div>
          )}
          <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Район работы</span>
            {role === EUserRole.MODERATOR ? (
              <span>{clanInfo.work_area || 'Не выбрано'}</span>
            ) : (
              <TextInput
                onChange={handleChangeWorkArea}
                placeholder="Введите значение"
                value={clanInfo.work_area}
                inputClassName="bg-ultrablack h-[19px] !pl-0"
              />
            )}
          </div>
        </CollapseContainer>
      </div>
    </div>
  );
};
