import {
  ChangeEvent,
  FC,
  MouseEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IOption } from 'interfaces';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import { roleOptions } from 'mock';
import { updateClanMemberThunk } from 'store/slices/clan/actions';
import { ID } from 'types/common';
import { rolesReverseMap, rolesValuesMap } from 'types/player';

import { CollapseContainer } from 'components/CollapseContainer';
import { CopyIcon } from 'components/CopyIcon';
import { DeleteModal } from 'components/DeleteModal';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import { maskPassword, notify } from 'utils';

interface InfoTableProps extends IPlayer {
  globalRole: EUserRole;
  onRemove: (id: ID) => void;
  onUpdatePlayer: (value: IUpdatedPlayerData) => void;
  clanId: ID;
  computerOptions: IOption[];
  isNewClan?: boolean;
}

const compareObjects = (
  oldObject: Record<string, any>,
  newObject: Record<string, any>
) => JSON.stringify(oldObject) === JSON.stringify(newObject);

export const InfoTable: FC<InfoTableProps> = ({
  globalRole,
  isNewClan,
  clanId,
  id,
  name,
  // launchedStreams,
  computerOptions,
  external_id,
  password,
  computer,
  // status,
  roles,
  role: playerRole,
  onRemove,
  onUpdatePlayer,
}) => {
  const roleClan = useMemo(
    () => roles.find((role) => role.clan_id === clanId),
    [roles, clanId]
  );

  const rolePlayer = useMemo(
    () => (isNewClan ? playerRole : roleClan?.role_id),
    [isNewClan, playerRole, roleClan]
  );

  const roleInitialValue = roleOptions.find(
    (option) => option.value === rolePlayer
  );

  const computerInitialValue = useMemo(
    () => computerOptions.find((option) => option.value === computer?.id),
    [computerOptions, computer]
  );

  const initialValue = {
    name: name,
    login: external_id,
    password: password || '',
    role: roleInitialValue || {
      value: '',
      label: '',
    },
    computer: computerInitialValue || {
      value: '',
      label: '',
    },
  };

  const [info, setInfo] = useState(initialValue);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const [isFocus, setFocus] = useState(false);

  const isShowButtons = !compareObjects(initialValue, info);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const isAdmin =
    (globalRole === EUserRole.ADMIN && id !== rolesValuesMap.root) ||
    globalRole === EUserRole.ROOT;

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  useEffect(() => {
    const computerInitialValue = computerOptions.find(
      (option) => option.value === computer?.id
    );

    if (computerInitialValue) {
      setInfo((prev) => ({ ...prev, computer: computerInitialValue }));
    }
  }, [computerOptions, computer]);

  // const handleNavigate = (event: MouseEvent) => {
  //   event.stopPropagation();

  //   navigate(`/admin-panel/clan/records`, {
  //     state: {
  //       clanId,
  //     },
  //   });
  // };

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleChangeName = (value: string) => {
    setInfo((prev) => ({ ...prev, name: value }));
  };

  const handleChangeLogin = (value: string) => {
    setInfo((prev) => ({ ...prev, login: value }));
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setInfo((prev) => ({ ...prev, role: value }));
    }
  };

  const handleSelectComputer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value) && !isNewClan) {
      setInfo((prev) => ({ ...prev, computer: value }));
    }
  };

  const handleGeneratePassword = (value: string) => {
    setInfo((prev) => ({ ...prev, password: value }));
  };

  const handleChangePassword = (value: string) => {
    setInfo((prev) => ({ ...prev, password: value }));
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemovePlayer = () => {
    onRemove(id);

    handleCloseModal();
  };

  const handleFocus = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(true);
  };

  const handleBlur = (_e: ChangeEvent<HTMLInputElement>) => {
    setFocus(false);
  };

  const handleClickSave = () => {
    if (isNewClan) {
      onUpdatePlayer({
        id,
        name: info.name,
        role: rolesReverseMap[info.role.value],
        login: info.login,
      });
    } else {
      if (
        info.login !== initialValue.login &&
        info.password === initialValue.password
      ) {
        notify.warning(warningMessages.UPDATE_LOGIN, {
          autoClose: 3000,
        });

        return;
      }

      dispatch(
        updateClanMemberThunk({
          id,
          clanId,
          roles,
          newRoleId:
            info.role.value !== initialValue.role.value
              ? info.role.value
              : undefined,
          name: info.name !== initialValue.name ? info.name : undefined,
          computer:
            info.computer.value !== initialValue.computer.value
              ? { id: info.computer.value, name: info.computer.label }
              : undefined,
          password:
            info.password !== initialValue.password ? info.password : undefined,
          external_id:
            info.login !== initialValue.login ? info.login : undefined,
        })
      );
    }
  };

  const handleClickCancel = () => {
    setInfo(initialValue);
  };

  const convertedPassword =
    !info.password && !isFocus ? maskPassword('password') : info.password;

  return (
    <div className="flex flex-col gap-[10px]">
      <div
        ref={containerRef}
        className={cn({ 'cursor-pointer': isAdmin })}
        onContextMenu={handleRightClick}
      >
        <CollapseContainer
          hidingBlock={
            <>
              {isAdmin ? (
                <Select
                  value={info.computer}
                  options={computerOptions}
                  onSelect={handleSelectComputer}
                  placeholder="Техника"
                  className="pl-[16px] border-t border-solid border-t-dark_product"
                />
              ) : (
                <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                  <span className="tpg-c2 text-tpg_light">Техника</span>
                  <span className="tpg-c1 pointer-events-none">
                    {info.computer.label || 'Не выбрана'}
                  </span>
                </div>
              )}
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Логин</span>
                {isAdmin ? (
                  <div className="flex flex-row items-center gap-[8px]">
                    <TextInput
                      value={info.login}
                      inputClassName="bg-ultrablack h-[20px] !pl-0"
                      className="w-full"
                      onChange={handleChangeLogin}
                    />
                    <CopyIcon value={external_id} />
                  </div>
                ) : (
                  <span className="tpg-c1 pointer-events-none">
                    {info.login}
                  </span>
                )}
              </div>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Пароль</span>
                <div className="flex flex-row justify-between items-center">
                  {!isAdmin ? (
                    <span className="tpg-c1 pointer-events-none">
                      {maskPassword(info.password || 'password')}
                    </span>
                  ) : (
                    <>
                      <TextInput
                        onChange={handleChangePassword}
                        value={convertedPassword || ''}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        inputClassName="bg-ultrablack h-[20px] !pl-0 !text-tpg_base"
                      />
                      <div className="flex flex-row gap-[8px]">
                        <GeneratePassword onClick={handleGeneratePassword} />
                        {info.password && <CopyIcon value={info.password} />}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          }
        >
          <div className="relative h-[61px] flex flex-col px-[16px] py-[10px]">
            <span className="tpg-c2 text-tpg_light">Пользователь</span>
            {isAdmin ? (
              <TextInput
                value={info.name}
                inputClassName="bg-ultrablack h-[20px] !pl-0"
                onChange={handleChangeName}
              />
            ) : (
              <span className="tpg-c1 pointer-events-none">{info.name}</span>
            )}
            {isShowDeleteButton && isAdmin && (
              <Button
                className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
                title="Удалить"
                onClick={handleOpenModal}
              />
            )}
          </div>
          {/* TODO: return when status data is ready */}
          {/* <div className="relative flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Статус</span>
          <span className="tpg-c1 pointer-events-none">
            {status || 'Активный'}
          </span> 
        </div> */}
          {isAdmin ? (
            <Select
              value={info.role}
              options={roleOptions}
              onSelect={handleSelectRole}
              placeholder="Роль"
              className="pl-[16px] border-t border-solid border-t-dark_product"
            />
          ) : (
            <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
              <span className="tpg-c2 text-tpg_light">Роль</span>
              <span className="tpg-c1 pointer-events-none">
                {info.role.label || 'Не выбран'}
              </span>
            </div>
          )}
          {/* TODO: return when launched streams data is ready */}
          {/* <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Записанных эфиров</span>
          <div className="flex flex-row justify-between items-center ">
            <span className="tpg-c1 pointer-events-none">
              {launchedStreams ?? 0}
            </span>
            {launchedStreams && (
              <span
                className="tpg-c2 text-bright_product hover:text-main_product cursor-pointer"
                onClick={handleNavigate}
              >
                Перейти к записям
              </span>
            )}
          </div>
        </div> */}
        </CollapseContainer>
        {isOpenDeleteModal && (
          <DeleteModal
            title="Вы уверены, что хотите удалить пользователя?"
            onClose={handleCloseModal}
            onRemove={handleRemovePlayer}
          />
        )}
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button
            onClick={handleClickSave}
            title="Сохранить"
            className="w-full"
          />
        </div>
      )}
    </div>
  );
};
