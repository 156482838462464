import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getComputers } from 'api/computer';
import { errorMessages, warningMessages } from 'constants/messages';
import { EUserRole } from 'constants/profile';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { IOption } from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';
import { mockHistory } from 'mock';
import { authSelector, playerSelector } from 'store';
import { banAccountThunk } from 'store/slices/account/actions';
import { updateClanMemberThunk } from 'store/slices/clan/actions';
import { getAllPlayersThunk, registerThunk } from 'store/slices/player/actions';
import { rolesValuesMap } from 'types/player';

import { AdminTable } from 'components/AdminTable';
import { DeleteModal } from 'components/DeleteModal';
import { HistoryInfo } from 'components/HistoryInfo';
import { Loader } from 'components/ui/Loader';
import { notify } from 'utils';

import { AccessList } from './AccessList';
import { BasicInfo } from './BasicInfo';

interface PlayerAdminPanelScreenProps {
  isNewPlayer?: boolean;
}

export type TPlayerInfo = Omit<IPlayer, 'computer_id' | 'access' | 'status'> & {
  status: IOption;
};

const playerInfo: TPlayerInfo = {
  id: 'new',
  banned: false,
  name: '',
  role: EUserRole.USER,
  clans: [],
  roles: [],
  status: {
    value: 'Не авторизован',
    label: 'Не авторизован',
  },
  launchedStreams: 0,
  external_id: '',
  email: '',
  password: '',
};

export const PlayerAdminPanelScreen: FC<PlayerAdminPanelScreenProps> = ({
  isNewPlayer,
}) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { list: players, pending } = useAppSelector(playerSelector);
  const { roles, role, id: accountId } = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const [initialPlayerValue, setInitialPlayerValue] = useState<TPlayerInfo>();
  const [player, setPlayer] = useState<TPlayerInfo>(playerInfo);
  const [initialPlayer, setInitialPlayer] = useState<TPlayerInfo>(playerInfo);
  const [computerOptions, setComputerOptions] = useState<IOption[]>([]);
  const [accesses, setAccesses] = useState<IClanPlayer[]>([]);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const clans = useMemo(
    () =>
      player.roles.map((role) => ({
        clanId: role.clan_id,
        clanName: role.clan_name,
        roleId: role.role_id,
        roleName: role.role_name,
      })),
    [player]
  );

  useEffect(() => {
    const getComputersData = async () => {
      try {
        const { data } = await getComputers();

        const options = data.map(({ id, name }) => ({
          value: String(id),
          label: name,
        }));

        setComputerOptions(options);
      } catch (error) {
        notify.error(errorMessages.GET_COMPUTERS);
      }
    };

    getComputersData();
  }, []);

  useEffect(() => {
    if (!players) {
      dispatch(getAllPlayersThunk());

      return;
    }

    const existedPlayer = players?.find((player) => String(player.id) === id);

    if (isNewPlayer || !existedPlayer) {
      setPlayer(playerInfo);

      return;
    }

    const initialExistedPlayer = {
      id: existedPlayer.id,
      banned: existedPlayer.banned,
      role: existedPlayer.role,
      clans: existedPlayer.clans,
      name: existedPlayer.name,
      roles: existedPlayer?.roles,
      status: {
        value: existedPlayer?.status,
        label: existedPlayer?.status,
      },
      computer: {
        id: existedPlayer?.computer?.id || '',
        name: existedPlayer?.computer?.name || '',
      },
      launchedStreams: existedPlayer?.launchedStreams,
      external_id: existedPlayer?.email,
      email: existedPlayer?.email,
      password: existedPlayer?.password,
    };

    setInitialPlayerValue(initialExistedPlayer);
    setPlayer(initialExistedPlayer);
    setInitialPlayer(initialExistedPlayer);
  }, [players, isNewPlayer]);

  const handleChangePlayerInfo = (info: TPlayerInfo) => {
    setPlayer(info);
  };

  const handleAddAccess = (value: IClanPlayer) => {
    setAccesses((prev) => [...prev, value]);
  };

  const handleRemoveAccess = (id: string) => {
    setAccesses((prev) => prev.filter((access) => access.clanId !== id));
  };

  const handleUpdateAccess = (value: IClanPlayer) => {
    setAccesses((prev) =>
      prev.map((access) =>
        access.clanId === value.clanId ? { ...access, ...value } : access
      )
    );
  };

  const handleUpdatePlayer = async () => {
    if (isNewPlayer) {
      if (player.password && player.name && player.email) {
        if (!accesses.length) {
          notify.warning(warningMessages.ADD_NEW_PLAYER);

          return;
        }

        const data = await dispatch(
          registerThunk({
            password: player.password,
            password2: player.password,
            name: player.name,
            email: player.email,
            accesses,
          })
        );

        if (data.type.includes('fulfilled')) {
          navigate(-1);
        }
      } else {
        notify.warning(warningMessages.PROVIDE_BASIC_INFO);
      }
    } else {
      if (
        initialPlayerValue?.password === player.password &&
        initialPlayerValue?.email !== player.email
      ) {
        notify.warning(warningMessages.UPDATE_LOGIN, {
          autoClose: 3000,
        });

        return;
      }

      const data = await dispatch(
        updateClanMemberThunk({
          id: player.id,
          name: initialPlayer.name !== player.name ? player.name : undefined,
          computer:
            initialPlayer.computer?.name !== player.computer?.name
              ? player.computer
              : undefined,
          password:
            initialPlayer.password !== player.password
              ? player.password
              : undefined,
          external_id:
            initialPlayer.external_id !== player.email
              ? player.email
              : undefined,
        })
      );

      if (data.type.includes('fulfilled')) {
        dispatch(getAllPlayersThunk());

        navigate(-1);
      }
    }
  };

  const handleRemovePlayer = () => {
    dispatch(banAccountThunk(String(player.id)));

    navigate(-1);
  };

  const highPriorityRoles = roles.filter(
    (role) =>
      role.role_name === EUserRole.ADMIN || role.role_name === EUserRole.ROOT
  );

  const isAdminForPlayer =
    !!highPriorityRoles.filter((role) =>
      clans.some((clan) => role.clan_id === clan.clanId)
    ).length || accountId === rolesValuesMap.root;

  const isAccessEditing =
    (isAdminForPlayer && !(id === rolesValuesMap.root)) ||
    role === EUserRole.ROOT;

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenModal = () => {
    setOpenDeleteModal(true);
  };

  const breakPoint = useBreakPoint();

  if (!player && !isNewPlayer) {
    return <span>Данные не загрузились</span>;
  }

  if (pending)
    return (
      <div className="h-full w-full absolute top-0 left-0 flex justify-center items-center">
        <Loader />
      </div>
    );

  const onSave =
    (!player.banned &&
      (isAdminForPlayer || accountId === rolesValuesMap.root)) ||
    isNewPlayer
      ? handleUpdatePlayer
      : undefined;

  const onRemove =
    id !== rolesValuesMap.root &&
    (accountId === rolesValuesMap.root || isAccessEditing) &&
    !player.banned
      ? handleOpenModal
      : undefined;

  return (
    <>
      <AdminTable
        title={
          isNewPlayer
            ? 'Новый пользователь'
            : `Пользователь ${player?.name} ${
                player.banned ? '(заблокирован)' : ''
              }`
        }
        listTitle={
          !(breakPoint === 'mobile' || breakPoint === 'tablet')
            ? 'Список доступов'
            : ''
        }
        historyTitle="История пользователя"
        isShowFooterController={true}
        isNewData={isNewPlayer}
        info={
          <BasicInfo
            playerInfo={player}
            computerOptions={computerOptions}
            onChange={handleChangePlayerInfo}
            isAccessEditing={isAccessEditing}
            isNewPlayer={isNewPlayer}
          />
        }
        history={<HistoryInfo history={mockHistory} />}
        list={
          <AccessList
            isNewPlayer={isNewPlayer}
            onAddAccess={handleAddAccess}
            onRemoveAccess={handleRemoveAccess}
            onUpdateAccess={handleUpdateAccess}
            accountId={String(player.id)}
            isBanned={player.banned}
            roles={roles}
            clans={isNewPlayer ? accesses : clans}
            isAccessEditing={isAccessEditing}
            highPriorityRoles={highPriorityRoles}
          />
        }
        onSave={onSave}
        onRemove={onRemove}
      />
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить пользователя?"
          onClose={handleCloseModal}
          onRemove={handleRemovePlayer}
        />
      )}
    </>
  );
};
