import { useEffect, useState } from 'react';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ITreeRooms } from 'interfaces/room';
import { roomActions, roomSelector } from 'store/slices/room';

import { TextInput } from 'components/ui';
import { filterTreeRoomsBySearch } from 'utils';

export const FilterStreams = () => {
  const [searchValue, setSearchValue] = useState('');
  const [treeRooms, setTreeRooms] = useState<ITreeRooms[]>([]);
  const [isAllStreamsHidden, setAllStreamsHidden] = useState(false);

  const { treeRooms: rooms } = useAppSelector(roomSelector);

  const dispatch = useAppDispatch();

  useEffect(
    () => () => {
      setSearchValue('');
    },
    []
  );

  useEffect(() => {
    if (searchValue) {
      const filteredTreeRooms = filterTreeRoomsBySearch(rooms, searchValue);

      setTreeRooms(filteredTreeRooms);
    } else {
      setTreeRooms(rooms);
    }
  }, [rooms, searchValue]);

  useEffect(() => {
    const isHiddenRooms = rooms.filter((room) => room.isHidden);

    if (isHiddenRooms.length) {
      setAllStreamsHidden(true);
    } else {
      setAllStreamsHidden(false);
    }
  }, [rooms]);

  const handleChangeSearch = (value: string) => {
    const filteredTreeRooms = filterTreeRoomsBySearch(rooms, value);

    setTreeRooms(filteredTreeRooms);

    setSearchValue(value);
  };

  const handleHideRoom = (
    livekitName: string,
    clanId: string,
    isHidden?: boolean
  ) => {
    dispatch(roomActions.roomHideController({ livekitName, clanId, isHidden }));
  };

  const handleHideClan = (clanId: string) => {
    const selectedClan = treeRooms.find((room) => room.clanId === clanId);

    selectedClan?.rooms.forEach(({ livekitName }) => {
      dispatch(
        roomActions.roomHideController({
          livekitName,
          clanId,
          isHidden: !selectedClan.isHidden,
        })
      );
    });
  };

  const handleClickAllStreams = () => {
    dispatch(roomActions.allRoomsHideController(!isAllStreamsHidden));

    setAllStreamsHidden(!isAllStreamsHidden);
  };

  return (
    <div>
      <TextInput
        onChange={handleChangeSearch}
        value={searchValue}
        placeholder="Начните поиск"
        searchIcon
        inputClassName="!rounded-t-[10px] !rounded-b-none !py-[9px] !pr-[12px] !pl-[28px] !border-none"
        className="[&>div>svg]:!ml-[6px] [&>div>svg]:!w-5 [&>div>svg]:!h-5"
        size="m"
      />
      <div className="max-h-[150px] mb-[5px] overflow-auto">
        <div className="flex flex-col px-[10px] pt-[10px] gap-[5px]">
          <span
            className={cn('cursor-pointer tpg-c2 text-bright_product', {
              '!text-tpg_base': isAllStreamsHidden,
            })}
            onClick={handleClickAllStreams}
          >
            Все трансляции
          </span>
          {treeRooms.length ? (
            treeRooms?.map(({ rooms, clanName, clanId, isHidden }) => (
              <div key={clanId} className="flex flex-col gap-[5px]">
                <span
                  className={cn(
                    'cursor-pointer ml-[15px] tpg-c2 text-bright_product',
                    {
                      '!text-tpg_base': isHidden,
                    }
                  )}
                  onClick={() => handleHideClan(clanId)}
                >
                  {clanName}
                </span>
                {rooms.map(
                  ({ publisherName, isHidden: isHiddenRoom, livekitName }) => (
                    <span
                      key={publisherName}
                      onClick={() => handleHideRoom(livekitName, clanId)}
                      className={cn(
                        'cursor-pointer ml-[30px] tpg-c2 text-bright_product',
                        {
                          'text-tpg_base': isHiddenRoom,
                        }
                      )}
                    >
                      {publisherName}
                    </span>
                  )
                )}
              </div>
            ))
          ) : (
            <span className="tpg-c2 text-tpg_base">0 в эфире</span>
          )}
        </div>
      </div>
    </div>
  );
};
