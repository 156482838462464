import { FC } from 'react';
import cn from 'classnames';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';

interface AddButtonProps {
  label: string;
  onClick: () => void;
  isActive?: boolean;
  className?: string;
}

export const AddButton: FC<AddButtonProps> = ({
  label,
  onClick,
  isActive,
  className,
}) => {
  const handleClickButton = () => {
    onClick();
  };

  return (
    <div
      className={cn(
        'h-[20px] gap-[4px] flex flex-row group cursor-pointer justify-center min-w-[140px] hover:text-bright_product transition',
        { 'text-bright_product': isActive },
        className
      )}
      onClick={handleClickButton}
    >
      <span className="tpg-c2">{label}</span>
      <PlusIcon
        className={cn(
          'group-hover:[&>g>path]:fill-bright_product w-[20px] h-[20px] [&>g>path]:transition',
          {
            '[&>g>path]:fill-bright_product': isActive,
          }
        )}
      />
    </div>
  );
};
