import { useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import { useAppDispatch, useBreakPoint, useClickOutside } from 'hooks';
import { ReactComponent as Search } from 'images/icons/search.svg';
import { clanActions, playerActions } from 'store';
import { useDebouncedCallback } from 'use-debounce';

import TextInput from 'components/ui/TextInput';

export const SearchInputByTable = () => {
  const [searchValue, setSearchValue] = useState({
    playerValue: '',
    clanValue: '',
  });

  const [isOpen, setOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const isPlayerQueryParam = useMemo(
    () => searchParams.get('filter') === 'players',
    [searchParams]
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const breakPoint = useBreakPoint();

  useClickOutside<HTMLDivElement, void>(containerRef, () => setOpen(false));

  useEffect(() => {
    if (isPlayerQueryParam) {
      dispatch(clanActions.setQuerySearch(''));

      setSearchValue((prev) => ({ ...prev, clanValue: '' }));
    } else {
      dispatch(playerActions.setQuerySearch(''));

      setSearchValue((prev) => ({ ...prev, playerValue: '' }));
    }
  }, [isPlayerQueryParam]);

  const setQuerySearch = useDebouncedCallback((value: string) => {
    if (isPlayerQueryParam) {
      dispatch(playerActions.setQuerySearch(value));
    } else {
      dispatch(clanActions.setQuerySearch(value));
    }
  }, 300);

  const handleChange = (value: string) => {
    setQuerySearch(value);

    if (isPlayerQueryParam) {
      setSearchValue((prev) => ({ ...prev, playerValue: value }));
    } else {
      setSearchValue((prev) => ({ ...prev, clanValue: value }));
    }
  };

  const handleClickOpen = () => {
    setOpen(!isOpen);
  };

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  return (
    <div ref={containerRef} className="relative flex justify-center">
      {isMobile && <Search onClick={handleClickOpen} />}
      {((isOpen && isMobile) || !isMobile) && (
        <TextInput
          value={
            isPlayerQueryParam ? searchValue.playerValue : searchValue.clanValue
          }
          placeholder="Начните поиск"
          searchIcon
          isReset
          className={cn('w-full bg-dark max-w-[540px] xl:max-w-[300px]', {
            '!absolute min-w-[300px] top-[38px] left-0 border border-solid rounded-[10px] border-tpg_light [&>div>input]:rounded-[10px] !max-w-full':
              isOpen && isMobile,
          })}
          theme="dark"
          onChange={handleChange}
        />
      )}
    </div>
  );
};
